import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Dashnav/Navbar";
import Header from "./Dashnav/Header";
import { Link, Route, useHistory } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import axios from "axios";

// import { getAuth, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'; 
function Settings() {

  const history = useHistory();
  const [dataready, setdataready] = useState(false);
  const [{ userdetails, loggedin, tradingpair }, dispatch] =
    useContext(GlobalContext);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    country: "",
    state: "",
    address: "",
  });
  const [loading, setLoading] = useState(true);

  // Fetch user data on component mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await db.collection("users").doc(user.uid).get();
          if (userDoc.exists) {
            setUserData({
              firstName: userDoc.data().firstName,
              lastName: userDoc.data().lastName,
              userName: userDoc.data().userName,
              email: user.email, // Email is retrieved from Firebase Auth
              country: userDoc.data().country,
              state: userDoc.data().state,
              address: userDoc.data().address,
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        // Handle case when no user is logged in
        setUserData({
          firstName: "",
          lastName: "",
          userName: "",
          email: "",
          country: "",
          state: "",
          address: "",
        });
        setLoading(false);
      }
    });

    // Cleanup the listener when the component is unmounted
    return () => unsubscribe();
  }, []);

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      if (user) {
        // Update user data in db
        await db.collection("users").doc(user.uid).update({
          firstName: userData.firstName,
          lastName: userData.lastName,
          state: userData.state,
          address: userData.address,
        });

        // Show success alert with SweetAlert2
        Swal.fire({
          icon: "success",
          title: "Profile Updated!",
          text: "Your profile has been updated successfully.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Show error alert with SweetAlert2
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an error updating your profile. Please try again.",
        confirmButtonText: "OK",
      });
    }
  };



  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user) {
      Swal.fire({
        icon: 'error',
        title: 'Not Logged In!',
        text: 'You must be logged in to change your password.',
      });
      return;
    }

    if (newPassword !== confirmNewPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Password Mismatch!',
        text: 'New password and confirmation do not match.',
      });
      return;
    }

    setLoading(true);

    try {
      // const authInstance = getAuth(); // Get the auth instance
      // Re-authenticate the user with the old password
      // const credential = EmailAuthProvider.credential(user.email, oldPassword);
      // await reauthenticateWithCredential(user, credential); // Reauthenticate with the old password

      // Update the user's password
      await user.updatePassword(newPassword);

      Swal.fire({
        icon: 'success',
        title: 'Password Changed!',
        text: 'Your password has been successfully updated.',
      });

      // Clear form fields after successful update
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error("Error updating password:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  // Loading state to show a loading spinner while fetching data
  // if (loading) {
  //   return <div>Loading...</div>;
  // }


  const [image, setImage] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleProfileImageChange = async (e) => {
    e.preventDefault();

    if (!image) {
      Swal.fire({
        icon: 'error',
        title: 'No File Selected',
        text: 'Please select an image to upload.',
      });
      return;
    }

    setLoading(true);

    // Prepare the form data for Cloudinary
    const formData = new FormData();
    formData.append('file', image);
    formData.append("upload_preset", "kh2yomwe"); // Replace with your Cloudinary preset
    formData.append("cloud_name", "draaumst7"); // Replace with your Cloudinary cloud name

    try {
      // Upload image to Cloudinary
      const response = await axios.post( "https://api.cloudinary.com/v1_1/draaumst7/image/upload", formData);
      const imageUrl = response.data.secure_url; // Get the URL of the uploaded image

      // Now update the user's profile with the new image URL
      const user = auth.currentUser;
      if (user) {
        // await user.updateProfile({
        //   photoURL: imageUrl, // Update the user's photo URL in Firebase
        // });
        await db.collection('users').doc(user.uid).update({
          photoURL: imageUrl, // Update the user's photoURL field in Firestore
        });


        Swal.fire({
          icon: 'success',
          title: 'Profile Image Updated',
          text: 'Your profile image has been successfully updated.',
        });

        // Optionally, save this image URL in your Firestore database as well
        // await db.collection('users').doc(user.uid).update({
        //   profileImage: imageUrl
        // });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text: 'There was an error uploading your image. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setLoading(false);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setLoading(false);
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data());
          setLoading(false);
          setdataready(true);
        } else {
          console.log("No such document!");
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };
  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    global.window && (global.window.location.href = "/home.html");
    const let3 = await history.push("/");
  };

  return (
    <div>
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=0.75, shrink-to-fit=no"
      />
      <meta name="description" content />
      <meta name="author" content />
      <link rel="shortcut icon" href="img/favicon.png" type="image/x-icon" />
      {/* <title>TradingFxCapital | Dashboard</title> */}
      <link
        rel="stylesheet"
        href="vendor/font-awesome-4.7.0/css/font-awesome.min.css"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="vendor/bootstrap-4.1.1/css/bootstrap.css"
        type="text/css"
      />
      <link
        href="vendor/datatables/css/dataTables.bootstrap4.css"
        rel="stylesheet"
      />
      <link
        href="vendor/datatables/css/responsive.dataTables.min.css"
        rel="stylesheet"
      />
      <link
        href="vendor/jquery-jvectormap/jquery-jvectormap-2.0.3.css"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="css/dark_grey_adminux.css" type="text/css" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
      {/* g-hide */}
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    iframe.goog-te-banner-frame {\n      display: none !important;\n    }\n  ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    iframe.skiptranslate {\n      display: none !important;\n    }\n  ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    body {\n      position: static !important;\n      top: 0px !important;\n    }\n  ",
        }}
      />
      {/* end-g-hide */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    div.alert {\n      padding: 4px 16px;\n      background: white;\n    }\n\n    input:text {\n      color: white;\n    }\n  ",
        }}
      />
      {/* /GetButton.io widget*/}
      {/* /GetButton.io widget */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    div.scrollmenu {\n      background-color: #333;\n      overflow: auto;\n      white-space: nowrap;\n    }\n\n    div.scrollmenu a {\n      display: inline-block;\n      color: white;\n      text-align: center;\n      padding: 14px;\n      text-decoration: none;\n    }\n\n    div.scrollmenu a:hover {\n      background-color: #777;\n    }\n\n    table,\n    td {\n      background: black;\n      color: white;\n    }\n\n    th {\n      background: #00000088;\n      color: white;\n    }\n  ",
        }}
      />
      <header className="navbar-fixed">
        <nav className="navbar navbar-toggleable-md navbar-inverse bg-faded">
          {/* Header can be reused across pages */}
          <Header onToggleSidebar={toggleSidebar} />
          {/* Sidebar */}
          <Navbar isVisible={isSidebarVisible} activePage={"Settings"} />
          <div className="d-flex mr-auto"> &nbsp;</div>
          <div id="google_translate_element" style={{ marginBottom: '20px' }}></div>
          <div className="sidebar-right pull-right ">
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item">
                <button
                  className="btn-link btn userprofile"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <span className="userpic">
                  <img src="https://img.icons8.com/?size=80&id=108652&format=png" alt="user pic" />
                </span>{" "}
                <span className="text">offic</span>
              </li>
              <li>
                 <a onClick={logout}className="btn btn-link icon-header">
                  <span className="fa fa-power-off" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div className="wrapper-content">
        <div className="container" style={{ maxWidth: "1300px" }}>
          {/* style="max-width:1400px" */}
          {/*<br><br> */}
          {/* <br> */}
          <title>TradingFxCapital | Account Settings</title>
          <h3 style={{ textAlign: "center" }}>Account Information</h3>
          <br />
          <div className="row">
            <div className="col-sm-16 col-md-16">
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-header">
                    <h3 style={{ color: "crimson", textAlign: "center" }} />
                    <h6 className="card-title">PROFILE SETTINGS</h6>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-8">
                        <label htmlFor="firstName" className="col-form-label">
                          <i className="fa fa-user" /> First name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={userData.firstName}
                          name="firstName"
                          id="firstName"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-8">
                        <label htmlFor="lastName" className="col-form-label">
                          <i className="fa fa-user" /> Last Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={userData.lastName}
                          name="lastName"
                          id="lastName"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-8">
                        <label htmlFor="userName" className="col-form-label">
                          <i className="fa fa-user" /> UserName
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={userData.userName}
                          id="userName"
                          style={{ color: "black" }}
                          readOnly
                        />
                      </div>
                      <div className="col-8">
                        <label htmlFor="email" className="col-form-label">
                          <i className="fa fa-email" /> Email
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          value={userData.email}
                          name="email"
                          id="email"
                          disabled
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-8">
                        <label htmlFor="country" className="col-form-label">
                          <i className="fa fa-location" /> Country
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={userData.country}
                          id="country"
                          style={{ color: "black" }}
                          readOnly
                        />
                      </div>
                      <div className="col-8">
                        <label htmlFor="state" className="col-form-label">
                          <i className="fa fa-location" /> State / Province
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={userData.state}
                          name="state"
                          id="state"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label htmlFor="address" className="col-form-label">
                          <i className="fa fa-address" /> Resident Address
                        </label>
                        <textarea
                          className="form-control"
                          rows={4}
                          name="address"
                          id="address"
                          onChange={handleInputChange}
                          required
                          value={userData.address}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <center>
                          <a className="btn btn-outline-primary" href="#cp">
                            Change Profile
                          </a>
                          <span> | </span>
                          <a className="btn btn-outline-primary" href="#cps">
                            Change Password
                          </a>
                          <span> || </span>
                          <input
                            className="btn btn-outline-info"
                            type="reset"
                            name="reset_btn"
                            defaultValue="Reset to Default"
                          />
                          <span> | </span>
                          <button className="btn btn-primary" type="submit">
                            Save Changes
                          </button>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row" id="cps">
            <div className="col-sm-16 col-md-8">
            <form autoComplete="off" onSubmit={handlePasswordChange}>
      <div className="card">
        <div className="card-header">
          <h6 className="card-title" style={{ textAlign: 'center', color: 'green' }}>
            UPDATE PASSWORD
          </h6>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <label htmlFor="oldPassword" className="col-16 col-form-label">
              Old Password
            </label>
            <div className="col-16">
              <input
                className="form-control"
                type="password"
                name="oldPassword"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="newPassword" className="col-16 col-form-label">
              New Password
            </label>
            <div className="col-16">
              <input
                className="form-control"
                type="password"
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="confirmNewPassword" className="col-16 col-form-label">
              Rewrite New Password
            </label>
            <div className="col-16">
              <input
                className="form-control"
                type="password"
                name="confirmNewPassword"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-16">
              <center>
                <a href="index.php">
                  <input
                    className="btn btn-outline-secondary"
                    type="reset"
                    name="clear_btn"
                    value="Clear"
                  />
                </a>
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  name="updatePwd_btn"
                  disabled={loading}
                >
                  {loading ? 'Changing Password...' : 'Update Password'}
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </form>
              <hr />
            </div>
            <div className="col-sm-16 col-md-8">
            <form onSubmit={handleProfileImageChange}>
      <div className="card">
        <div className="card-header">
          <h6 className="card-title">PROFILE IMAGE</h6>
        </div>
        <div className="card-body">
          <center>
            <div className="form-group row">
              <label
                htmlFor="profileImage"
                className="col-16 col-form-label"
              >
                Change Profile Image
              </label>
              <div className="col-16">
                <img
                  style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '30px',
                  }}
                  className="form-control"
                  src={image ? URL.createObjectURL(image) : 'https://img.icons8.com/?size=80&id=108652&format=png'}
                  alt="Profile Preview"
                />
                <input
                  className="form-control"
                  type="file"
                  name="profile"
                  id="profileImage"
                  onChange={handleFileChange}
                  required
                />
              </div>
            </div>
          </center>
          <div className="col-16">
            <center>
              <button
                className="btn btn-outline-primary"
                type="submit"
                name="changeImg_btn"
                disabled={loading}
              >
                {loading ? 'Uploading...' : 'Change Profile Image'}
              </button>
            </center>
          </div>
        </div>
      </div>
    </form>
              <hr />
              <a href="dashboards">
                <button className="btn btn-dark" style={{ color: "white" }}>
                  <i className="fa fa-undo" /> Back to Account
                </button>
              </a>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <div className="col-6">
                  <button
                    type="button"
                                       onClick={() => {
                      const walletAddress =
                        document.getElementById("referral_link").value;
                      navigator.clipboard
                        .writeText(walletAddress)
                        .then(() => {
                          Swal.fire({
                            icon: "success",
                            title: "Copied!",
                            text: "referral link copied to clipboard!",
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        })
                        .catch(() => {
                          Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to copy referral link.",
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        });
                    }}
                    className="btn btn-primary"
                  >
                    Copy Referral Link
                  </button>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    style={{ color: "black" }}
                    className="form-control"
                    id="referral_link"
                      value={`https://www.finance-tellars.com/register.html?${userdetails.myReferralId}`}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* TradingView Widget BEGIN */}
            <div className="tradingview-widget-container">
              <div className="tradingview-widget-container__widget" />
            </div>
            {/* TradingView Widget END */}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n.mgm {\n    border-radius: 7px;\n    position: fixed;\n    z-index: 90;\n    bottom: 120px;\n    right: 20px;\n    background: #fff;\n    border:4px solid #3f48cc;\n    padding: 10px 27px;\n    box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, .3);\n}\n\n.mgm a {\n    font-weight: 700;\n    display: block;\n    color: #3f48cc;\n}\n\n.mgm a,\n.mgm a:active {\n    transition: all .2s ease;\n    color: #3f48cc;\n}\n",
              }}
            />
            <div className="mgm" style={{ display: "none" }}>
              <div className="txt" style={{ color: "black" }} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="google_translate_element"
        style={{ visibility: "visible", display: "none" }}
      />
      {/* TradingView Widget BEGIN */}
      <div className="sidebar-right tradingview-widget-container ">
        <iframe
          allowTransparency="true"
          style={{ boxSizing: "border-box", height: "100%", width: "100%" }}
          src="https://www.tradingview-widget.com/embed-widget/screener/?locale=en#%7B%22width%22%3A%22220%22%2C%22height%22%3A600%2C%22defaultColumn%22%3A%22overview%22%2C%22defaultScreen%22%3A%22general%22%2C%22market%22%3A%22forex%22%2C%22showToolbar%22%3Afalse%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Afalse%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22marketstockspro.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22forexscreener%22%7D"
          frameBorder={0}
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n    .tradingview-widget-copyright {\n      font-size: 13px !important;\n      line-height: 32px !important;\n      text-align: center !important;\n      vertical-align: middle !important;\n      font-family: 'Trebuchet MS', Arial, sans-serif !important;\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright .blue-text {\n      color: #2962FF !important;\n    }\n\n    .tradingview-widget-copyright a {\n      text-decoration: none !important;\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright a:visited {\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright a:hover .blue-text {\n      color: #1E53E5 !important;\n    }\n\n    .tradingview-widget-copyright a:active .blue-text {\n      color: #1848CC !important;\n    }\n\n    .tradingview-widget-copyright a:visited .blue-text {\n      color: #2962FF !important;\n    }\n  ",
          }}
        />
      </div>
      {/* TradingView Widget END */}
    </div>
  );
}

export default Settings;
